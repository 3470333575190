@import url(~bootstrap/dist/css/bootstrap.css);
@font-face {
  font-family: 'Open Sans';
  src: url("fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  src: url("fonts/Open_Sans/OpenSans-Regular-webfont.eot");
  src: url("fonts/Open_Sans/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Open_Sans/OpenSans-Regular.woff2") format("woff2"), url("fonts/Open_Sans/OpenSans-Regular.woff") format("woff"), url("fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype"), url("fonts/Open_Sans/OpenSans-Regular-webfont.svg#open_sansregular") format("svg"); }

@font-face {
  font-family: 'Open Sans';
  src: url("fonts/Open_Sans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("fonts/Open_Sans/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

.offcanvas-backdrop.show {
  opacity: .8 !important; }

.main-page-container {
  background-color: #F5F5F5;
  font-family: "Open Sans";
  font-style: normal; }

ul.breadcrumb {
  padding: 28px 0px 26px;
  list-style: none;
  margin: 0; }
  ul.breadcrumb li {
    display: inline;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em; }
  ul.breadcrumb li + li:before {
    padding: 5px;
    color: #000000CC;
    content: "/\00a0"; }
  ul.breadcrumb li a {
    color: rgba(0, 0, 0, 0.4);
    text-decoration: none; }
  ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
    text-underline-offset: 1px; }

.wl-global__parent--selector .wl-desktop-dropdown--menu .wl-main-menu__dropdown .cmp-list__item-link:hover .cmp-list__item-text .cmp-list__item-description,
.wl-global__parent--selector .wl-footer__global .cmp-list__item-link:hover .cmp-list__item-title, .wl-global__parent--selector .wl-footer__global a:hover .cmp-list__item-title,
.wl-global__parent--selector .wl-desktop-dropdown--menu .wl-main-menu__dropdown .title .view-all a:hover,
.wl-global__parent--selector.xf-web-container a:where(:not(.wl-external *)) {
  text-underline-offset: 1px; }

.search-result-page-header h1 {
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #131313; }

.search-resul-page .container,
.product-listing-page .container {
  max-width: 1340px; }

.product-list-container {
  display: flex; }

h2.facet-header {
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.25);
  color: #131313; }

.close-mob-filter {
  background-color: #FFFFFF;
  border: none;
  display: flex;
  margin-right: 1rem;
  right: 0;
  position: absolute;
  top: 44px;
  font-size: 24px; }

.facet-accordion-panel {
  margin-top: 32px;
  max-height: 70vh;
  overflow-y: auto; }
  .facet-accordion-panel .accordion-item {
    border: none; }
  .facet-accordion-panel h2.accordion-header button {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0025em;
    color: #000000;
    background-color: #FFFFFF;
    padding: 1rem 0px;
    text-transform: capitalize; }

.accordion-button:focus {
  outline: none;
  border: none;
  box-shadow: none; }

.facet-options-panel .accordion-body {
  padding: 0px; }

header .main-nav {
  border: 1px solid #D9D9D9;
  border-right: none;
  box-shadow: 0 8px 6px -2px #D9D9D9; }

.facet-option-search {
  position: relative; }
  .facet-option-search .fa-icon {
    position: absolute;
    left: 10px;
    top: 37%;
    font-size: 11px;
    color: #00000099; }
  .facet-option-search input {
    text-indent: 25px;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.0025em;
    padding: 8px; }
    .facet-option-search input:focus-visible {
      outline: none;
      border: 1px solid rgba(49, 49, 49, 0.3);
      box-shadow: 0 0px 5px rgba(49, 49, 49, 0.2); }

.facet-option-search input::-webkit-input-placeholder {
  color: #131313;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-transform: capitalize; }

.facet-options-panel {
  width: 100%; }
  .facet-options-panel ul.facet-options-list {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.0025em;
    color: #000000;
    list-style: none;
    padding: 1px 8px 1px 0px;
    margin-top: 16px; }
    .facet-options-panel ul.facet-options-list li {
      display: flex;
      padding-bottom: 20px;
      gap: 7%;
      height: auto; }
      .facet-options-panel ul.facet-options-list li span.facet-checkbox-wrapper {
        margin-left: auto;
        order: 2;
        display: inline-flex;
        align-items: center;
        min-width: 56px;
        color: #00000099;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }
        .facet-options-panel ul.facet-options-list li span.facet-checkbox-wrapper .facet-checkbox-wrap {
          margin-left: auto;
          order: 2; }
        .facet-options-panel ul.facet-options-list li span.facet-checkbox-wrapper label {
          float: right;
          bottom: 4px; }
      .facet-options-panel ul.facet-options-list li .facet-option-label {
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap; }
  .facet-options-panel ul.facet-options-list-scroll-hidden {
    max-height: 570px;
    overflow: hidden; }
  .facet-options-panel ul.facet-options-list-scroll-show {
    max-height: 390px;
    overflow: auto; }
  .facet-options-panel .facet-label-wrapper {
    display: inline-flex;
    align-items: center; }

.apply-facet-mob,
.apply-sort-mob {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #FFFFFF;
  border-top: 1px solid rgba(19, 19, 19, 0.2);
  bottom: 0;
  position: fixed;
  width: 92%; }
  .apply-facet-mob button,
  .apply-sort-mob button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    background-color: #FFFFFF;
    border-radius: 3px; }
  .apply-facet-mob button:first-of-type,
  .apply-sort-mob button:first-of-type {
    background-color: #131313;
    color: #FFFFFF; }

.apply-sort-mob {
  margin-top: 93%; }

.custom-checkbox-wrap {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .custom-checkbox-wrap input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .custom-checkbox-wrap input[type=checkbox]:checked {
    background-color: #000000; }
  .custom-checkbox-wrap span.checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 3px; }
  .custom-checkbox-wrap input:checked ~ .checkmark {
    background: #131313;
    border: 1px solid #131313; }
  .custom-checkbox-wrap input:checked ~ .checkmark:after {
    display: block; }
  .custom-checkbox-wrap span.checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .custom-checkbox-wrap span.checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.search-resul-wrap {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column; }

.product-card {
  position: relative;
  display: block;
  background: #FFFFFF;
  border: 1px solid rgba(19, 19, 19, 0.2);
  border-radius: 3px; }
  .product-card:hover {
    border: 1px solid rgba(49, 49, 49, 0.3);
    box-shadow: 0px 2px 12px rgba(49, 49, 49, 0.2); }

.product-card-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  height: 100%; }
  .product-card-inner .image-data-container {
    display: flex;
    flex: 1; }
  .product-card-inner .product-image {
    width: 140px;
    border-radius: 3px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex; }
    .product-card-inner .product-image a .item-image {
      height: auto;
      max-height: 176px;
      max-width: 100%;
      width: auto; }
    .product-card-inner .product-image .product-featured-label {
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.0025em;
      color: #131313;
      padding: 8px 16px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 84.5px;
      height: 34px;
      background: #ffc805;
      border-radius: 0px 3px 0px 2px; }
  .product-card-inner .product-detail {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    margin-inline: 16px; }
    .product-card-inner .product-detail .product-title h3 a {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #131313;
      display: block;
      display: -webkit-box;
      max-width: 100%;
      text-decoration: none;
      word-break: break-word; }
      .product-card-inner .product-detail .product-title h3 a:hover {
        color: #115C36;
        text-decoration: underline; }
    .product-card-inner .product-detail .product-dt {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0025em;
      color: rgba(19, 19, 19, 0.8); }
    .product-card-inner .product-detail .product-authors {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.0025em;
      color: rgba(19, 19, 19, 0.8);
      white-space: pre-wrap; }
  .product-card-inner .product-other-details {
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 16px;
    border-left: 1px solid #D6D6D6;
    width: 211px; }
    .product-card-inner .product-other-details .tooltip-container {
      margin-bottom: 16px; }
      .product-card-inner .product-other-details .tooltip-container .tax-text {
        font-size: 14px;
        font-style: italic;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: #5A5A5A;
        margin-right: 10px; }
      .product-card-inner .product-other-details .tooltip-container .info-image-container {
        position: relative;
        display: inline-block; }
        .product-card-inner .product-other-details .tooltip-container .info-image-container img {
          cursor: pointer; }
        .product-card-inner .product-other-details .tooltip-container .info-image-container:hover .tax-info {
          display: block; }
        .product-card-inner .product-other-details .tooltip-container .info-image-container .tax-info {
          padding: 24px 16px;
          width: 300px;
          height: auto;
          background: white;
          border-radius: 4px;
          position: absolute;
          z-index: 1002;
          font-size: 14px;
          font-style: normal;
          line-height: 28px;
          letter-spacing: 0.0025em;
          text-align: left;
          bottom: 33px;
          right: -9px;
          display: none;
          filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2)); }
        .product-card-inner .product-other-details .tooltip-container .info-image-container .tax-info::after {
          content: "";
          position: absolute;
          filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));
          border: 10px solid;
          border-color: white transparent transparent transparent;
          bottom: -20px;
          right: 8px; }

.product-options {
  font-style: italic;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgba(19, 19, 19, 0.8);
  margin-bottom: 8px; }

.product-price {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0025em;
  color: black; }
  .product-price a {
    color: #131313;
    text-decoration: none; }

.modal-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1002;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0; }

.product-card .product-card-inner .learn-more {
  margin-top: auto; }
  .product-card .product-card-inner .learn-more .learn-more-btn {
    width: 196px;
    height: 45px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    background: #115C36;
    border: 1px solid #FFFFFF;
    border-radius: 1px; }
    .product-card .product-card-inner .learn-more .learn-more-btn:focus {
      background: black; }
    .product-card .product-card-inner .learn-more .learn-more-btn:hover {
      background: #198C53; }

.grey-bcground {
  background-color: #F4F4F4; }

.accordion-body-inner-div {
  border-bottom: 1px solid #D9D9D9;
  height: auto !important;
  max-height: 499px; }

.facet-options-list::-webkit-scrollbar,
.accordion-body-inner-div::-webkit-scrollbar {
  width: 5px; }

.facet-options-list::-webkit-scrollbar-thumb,
.accordion-body-inner-div::-webkit-scrollbar-thumb {
  background-color: #13131333;
  border-radius: 8px; }

.facet-options-list::-webkit-scrollbar-thumb:hover,
.accordion-body-inner-div::-webkit-scrollbar-thumb:hover {
  background: #9F9F9F; }

.no-result-found {
  padding: 30px 0; }
  .no-result-found p {
    font-weight: bold;
    font-size: 24px;
    line-height: 48px;
    letter-spacing: -0.02em;
    color: #000000;
    word-break: break-word; }

.paginate-wrapper {
  display: flex;
  flex-direction: column; }
  .paginate-wrapper .page-display {
    margin: 0 auto;
    padding-top: 10px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.0025em;
    color: rgba(19, 19, 19, 0.8); }

.pagination {
  order: 2;
  font-size: 10px;
  display: flex;
  list-style: none;
  outline: none;
  float: right;
  margin: 18px auto; }
  .pagination li > a {
    padding: 5px 7px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: #131313;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.005em; }
  .pagination .active > a {
    background-color: #131313;
    border-radius: 3px;
    box-shadow: 0px 0px 8px rgba(43, 94, 158, 0.2);
    color: #fff;
    cursor: disabled;
    pointer-events: none;
    display: inline !important; }
  .pagination .disabled > a {
    background-color: #ebecf3;
    border-color: #ebecf3;
    color: #fff;
    cursor: auto;
    display: inline !important; }
  .pagination .previous > a {
    margin-right: 10px;
    padding: 5px 5px; }
  .pagination .next > a {
    margin-left: 10px;
    padding: 5px 5px; }

.pagination > li > a.pagePrev,
.pagination > li > a.pageNext {
  cursor: pointer;
  pointer-events: auto; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background: #131313;
  box-shadow: 0px 0px 8px rgba(43, 94, 158, 0.2);
  border-radius: 3px;
  color: #fff;
  outline: none; }

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset; }

.pagination-before {
  float: right;
  font-family: nunitoSansRegular;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  padding: 10px; }

.pagination-before b {
  font-family: nunitoSansBold;
  font-size: 16px; }

.sort-wrapper select {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.0025em;
  color: #131313;
  border-radius: unset;
  padding: 16px 32px; }

ul.search-controls-removable_filter_pill {
  list-style-type: none;
  display: flex;
  padding-top: 10px;
  padding-left: unset;
  flex-wrap: wrap;
  pointer-events: auto; }
  ul.search-controls-removable_filter_pill li {
    box-sizing: border-box;
    padding: 12px 16px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(19, 19, 19, 0.2);
    border-radius: 3px;
    margin-right: 8px;
    margin-bottom: 8px; }
    ul.search-controls-removable_filter_pill li span {
      display: inline-flex;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.0025em;
      color: #131313; }
      ul.search-controls-removable_filter_pill li span span {
        margin-right: 40px;
        white-space: pre-wrap; }
      ul.search-controls-removable_filter_pill li span button {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border: none;
        font-size: 18px;
        background-color: transparent; }
        ul.search-controls-removable_filter_pill li span button .fa-icon {
          font-size: 16px; }
  ul.search-controls-removable_filter_pill li.clearfilter {
    border: none;
    background-color: transparent; }
    ul.search-controls-removable_filter_pill li.clearfilter button {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.0025em;
      color: #131313; }

.search-resul-page {
  min-height: calc(100vh - 570px); }
  .search-resul-page .search-result-wrapper {
    width: 100%;
    padding-bottom: 70px; }

.search-resul-header {
  display: none; }

.search-resul-mob-btn-div {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px; }
  .search-resul-mob-btn-div .search-resul-mob-btn {
    display: grid; }
    .search-resul-mob-btn-div .search-resul-mob-btn button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 19px;
      gap: 12px;
      background: #FFFFFF;
      border: 1px solid rgba(19, 19, 19, 0.2);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
      border-radius: 3px; }
      .search-resul-mob-btn-div .search-resul-mob-btn button span {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #131313;
        text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.25); }

.mob-sort-icon {
  min-width: 16px; }

.search-resul-inner-wrap {
  grid-template-columns: 1fr;
  display: grid;
  grid-row-gap: 8px;
  margin-bottom: 18px; }

.search-result-container-header {
  display: flex;
  flex-direction: column; }
  .search-result-container-header .search-result-content-tab-header {
    margin-left: 0;
    order: 0; }
    .search-result-container-header .search-result-content-tab-header .nav {
      padding-top: 7px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, auto));
      grid-gap: 20px;
      padding-bottom: 20px;
      justify-content: space-between; }
      .search-result-container-header .search-result-content-tab-header .nav button,
      .search-result-container-header .search-result-content-tab-header .nav a {
        font-weight: bold;
        font-size: 14px;
        color: rgba(19, 19, 19, 0.4);
        line-height: 28px;
        letter-spacing: -0.02em;
        border: none;
        background-color: unset; }
      .search-result-container-header .search-result-content-tab-header .nav button.active {
        color: #131313; }
        .search-result-container-header .search-result-content-tab-header .nav button.active span {
          border-bottom: 2px solid #131313;
          padding: 8px 12px; }

.search-result-content-tab-header.no-search-query .nav {
  grid-template-columns: unset; }

.sort-panel-wrapper {
  display: none;
  top: 0;
  left: 0;
  padding: 47px 16px 4px;
  position: fixed;
  z-index: 99999;
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  overflow-y: auto; }

.sort-panel-options {
  margin-top: 40px; }
  .sort-panel-options li {
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.0025em;
    text-transform: capitalize;
    color: rgba(19, 19, 19, 0.8);
    margin-bottom: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.sort-panel-options input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

.checkradio {
  position: absolute;
  right: 12px;
  height: 22px;
  width: 22px;
  background-color: #FFFFFF;
  border: 1px solid #131313;
  border-radius: 50%;
  transform: translateY(-0.075em); }

/* On mouse-over, add a grey background color */
.sort-panel-options:hover input ~ .checkradio {
  background-color: #FFFFFF;
  border: 1px solid #131313; }

/* When the radio button is checked, add a blue background */
.sort-panel-options input:checked ~ .checkradio {
  background-color: #FFFFFF;
  border-color: #000000; }

.checkradio:after {
  content: "";
  position: absolute;
  display: none; }

.sort-panel-options input:checked ~ .checkradio:after {
  display: block; }

.sort-panel-options .checkradio:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #000000; }

.tab-content > .active {
  display: block !important; }

.spinner {
  color: #3D3D3D;
  animation: spin infinite 5s linear;
  font-size: 35px;
  margin-bottom: 7px; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.loading-overlay-wrapper {
  position: relative; }

.loading-overlay {
  position: absolute;
  display: none;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  font-size: 1.2em;
  color: #FFF;
  background: rgba(245, 245, 245, 0.7);
  z-index: 800;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
  opacity: 1; }

.loading-overlay-content {
  margin: 20% auto; }

.loading-text {
  color: #3D3D3D;
  font-size: 16px;
  font-weight: 600; }

.placeholder-srp {
  display: block;
  grid-column: 1 / span 2; }

.nofixHeader {
  position: relative !important;
  top: 0px; }

.notopMpagecont {
  margin-top: 0px !important; }

.osano-cm-widget {
  display: none; }

@media (max-width: 1080px) {
  .facet-panel-wrapper {
    display: none;
    top: 0;
    left: 0;
    padding: 47px 16px 4px;
    position: fixed;
    z-index: 99999;
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    overflow-y: auto; } }

@media (min-width: 641px) {
  .placeholder-srp {
    grid-column: 1 / span 3; }
  .search-result-container-header {
    flex-direction: row; }
    .search-result-container-header .search-result-content-tab-header {
      margin-left: auto;
      order: 2;
      min-width: 180px; }
      .search-result-container-header .search-result-content-tab-header .nav {
        padding-top: 7px; }
        .search-result-container-header .search-result-content-tab-header .nav button,
        .search-result-container-header .search-result-content-tab-header .nav a {
          font-weight: bold;
          font-size: 14px;
          color: rgba(19, 19, 19, 0.4);
          line-height: 28px;
          letter-spacing: -0.02em;
          border: none;
          background-color: unset; }
        .search-result-container-header .search-result-content-tab-header .nav button.active {
          color: #131313; }
  .paginate-wrapper {
    flex-direction: row; }
    .paginate-wrapper .page-display {
      margin: 0px; }
    .paginate-wrapper .pagination {
      margin: 12px 0px;
      margin-left: auto;
      font-size: 14px; }
      .paginate-wrapper .pagination .previous > a {
        padding: 5px 12px; }
      .paginate-wrapper .pagination .next > a {
        padding: 5px 12px; }
      .paginate-wrapper .pagination li > a {
        padding: 5px 12px; }
  .apply-facet-mob,
  .apply-sort-mob {
    width: 96%; }
  .product-card-inner .product-detail .product-title h3 a {
    font-size: 14px; }
  .product-card-inner .product-options {
    font-size: 12px;
    letter-spacing: -0.02em; }
  .search-result-page-header h1 {
    font-size: 18px;
    line-height: 32px;
    padding-top: 9px; }
  .search-result-container-header .search-result-content-tab-header .nav {
    padding: 0; } }

/* style specific to desktop screen */
@media (min-width: 1081px) {
  h2.facet-header {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0025em; }
  .facet-panel-wrapper {
    display: block !important;
    position: relative;
    flex-shrink: 0;
    min-height: 300px;
    margin-right: 56px;
    margin-bottom: 50px;
    width: 22%;
    max-width: 270px; }
  .facet-accordion-panel {
    margin-top: 32px;
    max-height: fit-content; }
    .facet-accordion-panel .accordion-item {
      border: none; }
    .facet-accordion-panel h2.accordion-header button {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0025em;
      color: #131313;
      background-color: #F5F5F5;
      padding: 1rem 0px;
      text-transform: capitalize; }
    .facet-accordion-panel .accordion-item {
      background-color: #F5F5F5; }
  ul.breadcrumb {
    padding-bottom: 40px; }
  .search-result-container {
    margin-top: 60px; }
  .search-resul-mob-btn-div {
    display: none; }
  .search-resul-header {
    display: flex; }
    .search-resul-header h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: #000000; }
    .search-resul-header .sort-wrapper {
      margin-left: auto;
      order: 2; }
    .search-resul-header button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      gap: 12px;
      background: #FFFFFF;
      border: 1px solid rgba(19, 19, 19, 0.2);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
      border-radius: 3px; }
      .search-resul-header button span {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #131313;
        text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.25); }
  .close-mob-filter {
    display: none; }
  .apply-facet-mob {
    display: none; }
  .search-result-content-tab-header.no-search-query .nav {
    display: unset;
    float: right; }
  .search-resul-page .search-result-wrapper {
    width: 74%;
    margin-left: auto; }
  .product-card-inner .product-detail .product-title h3 a {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0025em; }
  .product-card-inner .product-detail .product-dt {
    font-size: 14px; }
  .product-card-inner .product-detail .product-authors {
    font-size: 12px; }
  .product-card-inner .product-options {
    font-size: 12px;
    letter-spacing: 0.0025em; }
  .search-result-page-header h1 {
    font-size: 24px;
    line-height: 24px;
    padding-top: 9px; } }

@media (max-width: 475px) {
  .facet-option-search input {
    font-size: 16px; } }

@media (min-width: 1200px) {
  .facet-panel-wrapper {
    width: 21%; } }

@media (max-width: 640px) {
  .product-card {
    height: auto; }
  .product-card-inner {
    flex-direction: column; }
  .image-data-container {
    padding-bottom: 16px;
    border-bottom: 1px solid #D6D6D6; }
  .product-card-inner .product-image {
    align-items: flex-start;
    width: 90px;
    height: 113.1px; }
  .product-card-inner .product-image a .item-image {
    max-height: 113.1px; }
  .product-card-inner .product-image .product-featured-label {
    height: 26px;
    width: 71px;
    padding: 4px 8px; }
  .product-card-inner .product-other-details {
    width: 100%;
    border-left: none;
    padding-left: 0;
    padding-top: 16px; }
  .tax-text {
    font-size: 14px;
    margin-inline: 4px; }
  .product-card .product-card-inner .product-other-details .tooltip-container .info-image-container .tax-info[data-text="Price and Tax Information"] {
    right: -93px; }
  .product-card .product-card-inner .product-other-details .tooltip-container .info-image-container .tax-info[data-text="Price and Tax Information"]::after {
    right: 90px; }
  .product-card .product-card-inner .product-other-details .tooltip-container .info-image-container .tax-info[data-text="VAT Information"] {
    right: -154px; }
  .product-card .product-card-inner .product-other-details .tooltip-container .info-image-container .tax-info[data-text="VAT Information"]::after {
    right: 150px; }
  .tooltip-container img {
    width: 12px; }
  .product-card .product-card-inner .product-other-details .learn-more .learn-more-btn {
    width: 100%;
    height: 45px; } }

.container-fluid {
  padding-left: 11px;
  padding-right: 11px;
  max-width: 1600px;
  margin: 0 auto; }

.selected-filters {
  display: none !important; }

@media (min-width: 641px) {
  .container-fluid {
    padding-left: 40px;
    padding-right: 40px; } }

@media (min-width: 1081px) {
  .container-fluid {
    padding-left: 100px;
    padding-right: 100px; }
  .selected-filters {
    display: block !important; } }
